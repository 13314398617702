import Vue from 'vue'
import VueRouter from 'vue-router'

// const Index = () => import('../views/Index.vue')
const Canvas = () => import('../views/Canvas.vue')
const MobilePage = () => import('../views/MobilePage.vue')

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    component: window.innerWidth < 768 ? MobilePage : Canvas
  }
];

// const routes = [
//   {
//     path: '/',
//     name: 'Canvas',
//     component: Canvas,
//   },
  // {
  //   path: '/index',
  //   name: 'Index',
  //   meta: {
  //     title: '首页'
  //   },
  //   component: Index,
  //   children: [
  //     {
  //       path: 'canvas',
  //       name: 'Canvas',
  //       component: Canvas
  //     },
  //   ]
  // }

// ]
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

// // 路由守卫，
// router.beforeEach((to, from, next) => {
//   // 跳转到非登录页面的其他页面时需先判断是否登录
//   let IS_LOGIN = store.get(virtual_path + 'token')
//   if (to.path !== '/login') {
//     // 未登录则跳转到登录页面
//     if (IS_LOGIN != 'Unnamed' && IS_LOGIN) {
//       next()
//     } else {
//       next('/login')
//     } // path就是配置路由文件里的路由path(属性值一定要相同)
//   } else {
//     // 已登录则跳转到首页
//     if (IS_LOGIN != 'Unnamed' && IS_LOGIN) next('/home')
//     else next()
//   }
// })

export default router
